import { useState, useEffect } from 'react'

export default function useContainerWidth(containerRef) {
	const [width, setWidth] = useState(0)

	useEffect(() => {
		// Función para actualizar el ancho
		function updateWidth() {
			setWidth(containerRef.current.offsetWidth)
		}

		// Añadir el event listener al resize
		window.addEventListener('resize', updateWidth)

		// Actualizar el ancho inicialmente
		updateWidth()

		// Remover el event listener al desmontar el componente
		return () => window.removeEventListener('resize', updateWidth)
	}, [containerRef])

	// Retornar el ancho del contenedor
	return width
}