import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Datetime from 'react-datetime'
import { getInicioAnho, getMomentObject, getServerDate, hasEqualDateValue } from 'utils/date'


export default function SelectDateRange(props) {
	const { desde = getInicioAnho(), hasta = new Date(), onChange, btnTitle = 'Cambiar' } = props

	const desdeVal = getMomentObject(desde)
	const hastaVal = getMomentObject(hasta)

	const [fechaInicio, setFechaInicio] = useState(desdeVal)
	const [fechaFin, setFechaFin] = useState(hastaVal)

	const noHayCambios = hasEqualDateValue(desdeVal, fechaInicio) && hasEqualDateValue(hastaVal, fechaFin)

	const handleSubmit = () => {
		onChange(fechaInicio.format('yyyy-MM-DD'), fechaFin.format('yyyy-MM-DD'))
	}

	return (
		<div className="d-flex gap-3 align-items-center">
			<Form.Group className="mb-2" controlId="txtFechaInicio">
				<Form.Label className="mb-1">Desde</Form.Label>
				<Datetime
					dateFormat="YYYY-MM-DD"
					timeFormat={false}
					closeOnSelect={true}
					strictParsing={false}
					inputProps={{ placeholder: "YYYY-MM-DD", id: 'txtFechaInicio', className: 'form-control form-control-sm' }}
					value={fechaInicio}
					onChange={setFechaInicio}
				/>
			</Form.Group>
			<Form.Group className="mb-2" controlId="txtFechaFin">
				<Form.Label className="mb-1">Hasta</Form.Label>
				<Datetime
					dateFormat="YYYY-MM-DD"
					timeFormat={false}
					closeOnSelect={true}
					strictParsing={false}
					inputProps={{ placeholder: "YYYY-MM-DD", id: 'txtFechaFin', className: 'form-control form-control-sm' }}
					value={fechaFin}
					onChange={setFechaFin}
				/>
			</Form.Group>
			<Button
				className="mt-4 mb-1"
				onClick={handleSubmit}
				size='sm'
				disabled={noHayCambios}
			>
				{btnTitle}
			</Button>
		</div>
	)
}