import React, { useRef } from 'react'
import { Pagination } from 'react-bootstrap'
import { range } from 'utils/range'
import useContainerWidth from 'hooks/useContainerWidth'



export default function Paginacion(props) {
	/*
		*Comportamiento*
		Se muestran 
		- Primera (disabled si se está en la primera página)
		- Anterior (disabled si se está en la primera página)
		- 2 páginas a la izquierda (cuando se puede)
		- 2 a la derecha de la actual (cuando se puede)
		- Siguiente (disabled si se está en la última página)
		- Última (disabled si se está en la última página)
	*/
	const { pagina, numPaginas, setPagina } = props

	const containerRef = useRef(null)

	const containerWidth = useContainerWidth(containerRef)
	const buttonSize = containerWidth < 550 ? "sm" : null

	const useSmallButtons = containerWidth < 550
	const isLastPage = pagina === numPaginas
	const isFirstPage = pagina === 1

	return (
		<div ref={containerRef}>
			<Pagination size={buttonSize}>
				{useSmallButtons ? (
					<Pagination.First
						onClick={() => setPagina(1)}
						disabled={isFirstPage}
					/>
				) : (
					<Pagination.Item
						onClick={() => setPagina(1)}
						disabled={isFirstPage}
					>
						Primera
					</Pagination.Item>
				)}
				{useSmallButtons ? (
					<Pagination.Prev
						onClick={() => setPagina(pagina - 1)}
						disabled={isFirstPage}
					/>
				) : (
					<Pagination.Item
						onClick={() => setPagina(pagina - 1)}
						disabled={isFirstPage}
					>
						Anterior
					</Pagination.Item>
				)}
				{range(pagina - 2, pagina + 2).map(step => {
					return (
						(step > 0 && step <= numPaginas) && (
							<Pagination.Item
								onClick={() => setPagina(step)}
								key={step}
								disabled={step === pagina}
							>
								{step}
							</Pagination.Item>
						)
					)
				})}
				{useSmallButtons ? (
					<Pagination.Next
						onClick={() => setPagina(pagina + 1)}
						disabled={isLastPage}
					/>
				) : (
					<Pagination.Item
						onClick={() => setPagina(pagina + 1)}
						disabled={isLastPage}
					>
						Siguiente
					</Pagination.Item>
				)}

				{useSmallButtons ? (
					<Pagination.Last
						onClick={() => setPagina(numPaginas)}
						disabled={isLastPage}
					/>
				) : (
					<Pagination.Item
						onClick={() => setPagina(numPaginas)}
						disabled={isLastPage}
					>
						Última
					</Pagination.Item>
				)}
			</Pagination>
		</div>
	)
}
