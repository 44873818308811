import React from 'react'
import { MultiSelect } from 'react-multi-select-component'
import ItemRenderer from './ItemRenderer'
import './MultiSelectCustom.css'

/*
 *	Adaptado https://www.npmjs.com/package/react-multi-select-component
 */
export default function MultiSelectCustom(props) {
	const { options, values, onChange, size = '', overrideStrings = {}, ...rest } = props

	const defaultStrings = {
		allItemsAreSelected: 'Todos',
		clearSearch: 'Limpiar búsqueda',
		clearSelected: 'Limpiar',
		noOptions: 'Sin opciones',
		search: 'Búsqueda',
		selectAll: 'Todos',
		selectAllFiltered: 'Seleccionar todos (filtrados)',
		selectSomeItems: 'Selecccionar...',
		create: 'Crear',
	}

	const mergedStrings = { ...defaultStrings, ...overrideStrings}

	const sizeClass = size !== '' ? `rmsc-${size}` : ''

	return (
		<MultiSelect
			options={options}
			value={values}
			onChange={onChange}
			labelledBy="Select"
			overrideStrings={mergedStrings}
			ItemRenderer={ItemRenderer}
			className={sizeClass}
			{...rest}
		/>
	)
}