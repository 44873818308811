export default function ItemRenderer(props) {
	const { checked, option, onClick, disabled } = props

	return (
		<div className={`item-renderer ${disabled ? "disabled" : ""}`}>
			<input
				type="checkbox"
				className="form-check-input"
				onChange={onClick}
				checked={checked}
				tabIndex={-1}
				disabled={disabled}
			/>
			<span>{option.label}</span>
		</div>
	)
}