import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'

import './FiltrosFormWrapper.css'

export default function FiltrosWrapper(props) {

	return (
		<div className="filtros-wrapper">
			<Form>
				<Row>
					<Col className="d-flex align-items-center gap-2">
						<div className="filtros-icon"><i className="fa-solid fa-sliders" /></div>
						{props.children}
					</Col>
				</Row>
			</Form>
		</div>
	)
}