import { Spinner } from 'react-bootstrap';

export default function LoadingSpinner(props) {
	const { animation = 'border', variant = 'primary', size="lg" } = props
	
	return (
		<Spinner animation={animation} role="status" variant={variant} size={size}>
			<span className="sr-only">Loading...</span>
		</Spinner>
	)
}
